<template>
    <div class="base-container">
        <el-date-picker v-model="data.valueTime" type="date" placeholder="选择日期" :clearable="false"
                        value-format="yyyy-MM-dd"
                        style="width: 140px; margin: 0 10px 10px 10px" size="mini">
        </el-date-picker>
        <el-button type="primary" @click="dateValue()" size="mini" :loading="loading">查询</el-button>
        <div style="height: 95%;" v-loading="loading">
            <iframe ref="Iframe" :src="src" frameborder="0" width="100%" height="100%" :name="name"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sellWeighReport',
    data() {
        return {
            src: '',
            name: '销售过磅单报表',
            data: {
                valueTime: ''
            },
            loading: false,
            timer: ''
        }
    },
    mounted() {
        this.$set(this.data, 'valueTime', this.$utils.toDateString(new Date(), 'yyyy-MM-dd'))
        this.dateValue()
    },
    methods: {
        dateValue() {
            if (![null, undefined, ''].includes(this.data.valueTime)) {
                this.loading = true
                this.src = `https://aymzk.cn/ureport/preview?_u=db:purchase.ureport.xml&outtime=${this.data.valueTime}&math=${Math.random()}`
                if (this.timer === this.data.valueTime) {
                    this.loading = false
                } else {
                    const iframe = this.$refs.Iframe
                    this.timer = this.data.valueTime
                    if (iframe.attachEvent) {
                        iframe.attachEvent('onload', () => {
                            this.loading = false;
                        });
                    } else {
                        iframe.onload = () => {
                            this.loading = false;
                        };
                    }
                }
            } else {
                this.$message.warning('请选择查询日期')
            }
        }
    }
}
</script>
